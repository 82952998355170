
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0,5px,5px,5px;
  padding: 10px,10px,10px,10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

header {
  background-color: #f9f9f9;
  border-bottom: 2px solid #ccc;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

header img {
  max-height: 80px;
}

.container {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin-top: 20px;
}

h1 {
  color: #D16B00;
  margin-top: 0;
  text-align: center;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"], select, input[type="file"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  background-color: #D16B00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}

footer {
  background-color: #f9f9f9;
  border-top: 2px solid #ccc;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: auto;
}

@media (max-width: 600px) {
  .container {
      padding: 10px;
  }
}
