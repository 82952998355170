/* ThankYou.module.css */
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(to right, #4CAF50, #81C784); /* Gradient background */
}
header {
    background-color: #f9f9f9;
    border-bottom: 2px solid #ccc;
    width: 100%;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

header img {
    max-height: 80px;
    width: 95%;
    height: 450px;
}
.thankYouContainer {
    text-align: center;
    background: #fff;
    padding: 30px;
    margin: 80px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 500px;
}
h1 {
    color: #4CAF50;
    font-size: 2rem;
    margin-top: 0;
}
p {
    color: #333;
    font-size: 1rem;
    margin-bottom: 0;
}
.printButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}
.printButton:hover {
    background-color: #45a049;
}
footer {
    background-color: #f9f9f9;
    border-top: 2px solid #ccc;
    padding: 10px 20px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: auto;
}
@media (max-width: 600px) {
    .thankYouContainer {
        padding: 20px;
        width: 90%;
    }
    h1 {
        font-size: 1.5rem;
    }
    p {
        font-size: 0.9rem;
    }
}
/* ThankYou.module.css */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* semi-transparent white background */
    z-index: 9999; /* ensures loader is on top of everything */
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
